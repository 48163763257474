var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('topHeader',{attrs:{"userData":_vm.user}}),_c('b-container',[_c('div',{staticClass:"profiledetail"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"5"}}),_c('b-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"8","sm":"7"}},[_c('div',{staticClass:"__movleft"},[_c('p',{staticClass:"profiledetail__name"},[(_vm.userData)?[_c('span',[_vm._v("Hey, I'm")]),_vm._v(" "+_vm._s(_vm.userData.fullName)+" ")]:_vm._e()],2)])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"5"}},[_c('div',{staticClass:"__fixedimg"},[(_vm.userData)?[_c('profileCarousel',{attrs:{"user":_vm.userData,"userSlug":this.$route.params.userSlug,"loggedInUser":_vm.user}})]:_vm._e(),(
                _vm.userData &&
                (_vm.userData.profile.facebook_url ||
                  _vm.userData.profile.twitter_url ||
                  _vm.userData.profile.instagram_url ||
                  _vm.userData.profile.linkedin_url)
              )?[_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"},[(_vm.userData.profile.twitter_url)?_c('a',{attrs:{"href":("" + (_vm.userData.profile.twitter_url)),"target":"_blank"}},[_c('img',{staticClass:"__sociaimgs",attrs:{"src":require('../assets/img/twitter.png')}})]):_vm._e(),(_vm.userData.profile.instagram_url)?_c('a',{attrs:{"href":("" + (_vm.userData.profile.instagram_url)),"target":"_blank"}},[_c('img',{staticClass:"__sociaimgs",attrs:{"src":require('../assets/img/instagram.png')}})]):_vm._e(),(_vm.userData.profile.linkedin_url)?_c('a',{attrs:{"href":("" + (_vm.userData.profile.linkedin_url)),"target":"_blank"}},[_c('img',{staticClass:"__sociaimgs",attrs:{"src":require('../assets/img/linkedin.png')}})]):_vm._e(),(_vm.userData.profile.facebook_url)?_c('a',{attrs:{"href":("" + (_vm.userData.profile.facebook_url)),"target":"_blank"}},[_c('img',{staticClass:"__sociaimgs",attrs:{"src":require('../assets/img/facebook.png')}})]):_vm._e()])]),_c('hr')]:_vm._e()],2)]),_c('b-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"8","sm":"7"}},[_c('div',{staticClass:"__movleft"},[_c('div',{staticClass:"profiledetail__status"},[_c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('p',{staticClass:"profiledetail__status-exper"},[_c('img',{staticClass:"profiledetail__status-img",attrs:{"src":require('../assets/img/briefcase.png')}}),_vm._v(" Work Preferences: "),(_vm.userData)?[(
                          _vm.userData.profile.work_preferences === 'full-time'
                        )?_c('span',[_vm._v(" Full time")]):_vm._e(),(
                          _vm.userData.profile.work_preferences === 'part-time'
                        )?_c('span',[_vm._v("Contract")]):_vm._e(),(!_vm.userData.profile.work_preferences)?_c('span',[_vm._v("N/A")]):_vm._e()]:_vm._e()],2)]),_c('b-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('p',{staticClass:"profiledetail__status-exper"},[_c('img',{staticClass:"profiledetail__status-img",attrs:{"src":require('../assets/img/currency.png')}}),_vm._v(" Availability: "),(_vm.userData)?[_c('span',[_vm._v("₦"+_vm._s(_vm.userData.profile.work_rate)+" (Per)hour")])]:_vm._e()],2)])],1),_c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"6"}},[_c('p',{staticClass:"profiledetail__status-exper"},[_c('img',{staticClass:"profiledetail__status-img",attrs:{"src":require('../assets/img/epushpin.png')}}),(_vm.userData)?[_vm._v(" Location: "),_c('span',[_vm._v(_vm._s(((_vm.userData.profile.city) + ", " + (_vm.userData.profile.country))))])]:_vm._e()],2)])],1)],1),_c('p',{staticClass:"profiledetail__bio"},[_vm._v("Bio")]),(_vm.userData)?[_c('p',{staticClass:"profiledetail__biodetail"},[_vm._v(" "+_vm._s(_vm.userData.profile.bio.length > 500 ? ((_vm.userData.profile.bio.substr(0, 500)) + "...") : _vm.userData.profile.bio)+" ")])]:_vm._e(),_c('p',{staticClass:"profiledetail__bio"},[_vm._v("Skills")]),_c('hr'),_c('div',{staticClass:"__emptyboxdow",staticStyle:{"backgroundcolor":"white"}},[(_vm.userData)?[(!_vm.userData.profile.skills)?_c('p',{staticClass:"__emptyboxdow-pr"},[_vm._v(" You have not added any expertises, click on edit to add your area(s) of expertise ")]):_vm._e(),(_vm.userData.profile.skills)?_c('div',[_vm._l((_vm.userData.profile.skills.split(
                      ','
                    )),function(skill,index){return [_c('p',{key:index,staticClass:"custom-chips",staticStyle:{"justifycontent":"center"}},[_vm._v(" "+_vm._s(skill)+" ")])]})],2):_vm._e()]:_vm._e()],2),_c('p',{staticClass:"profiledetail__bio"},[_vm._v("Meetings and Events")]),_c('hr'),_vm._l((_vm.mentorshipSessions),function(session){return [_c('div',{key:session.id,staticClass:"mentocard border-radius"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"11","lg":"11","md":"11","sm":"10"}},[_c('p',{staticClass:"mentocard__cofp"},[_vm._v(_vm._s(session.name))])])],1),_c('div',{staticClass:"mentocard__flexdiv"},[_c('p',{staticClass:"mentocard__flexdiv--disc"},[_c('img',{attrs:{"src":require('../assets/img/duration.png')}}),_vm._v(" Duration: "),_c('span',[_vm._v(_vm._s(session.duration)+" h:m")])]),_c('p',{staticClass:"mentocard__flexdiv--disc"},[_c('img',{attrs:{"src":require('../assets/img/epushpin.png')}}),_vm._v(" Location: "),(
                        session.location.toLowerCase().includes('digital') &&
                        session.link.toLowerCase().includes('google')
                      )?[_c('img',{staticStyle:{"marginright":"3px"},attrs:{"src":require('../assets/img/google-meet.png')}})]:_vm._e(),(
                        session.location.toLowerCase().includes('digital') &&
                        session.link.toLowerCase().includes('zoom')
                      )?[_c('img',{staticStyle:{"marginright":"3px"},attrs:{"src":require('../assets/img/zoom.png')}})]:_vm._e(),(
                        !session.location.toLowerCase().includes('digital')
                      )?[_c('img',{staticStyle:{"marginright":"3px"},attrs:{"src":require('../assets/img/inperson.png')}})]:_vm._e(),_c('span',[_vm._v(_vm._s(session.location))])],2),_c('p',{staticClass:"mentocard__flexdiv--disc"},[_c('img',{attrs:{"src":require('../assets/img/currency.png')}}),_vm._v(" Availability: "),_c('span',[_vm._v(_vm._s(session.payment_mode))])])]),(_vm.user.slug !== _vm.slug)?_c('router-link',{staticClass:"btn __mentbtn",attrs:{"to":("/" + _vm.slug + "/book-mentorship/" + (session.slug))}},[_vm._v("Book Event")]):_vm._e()],1)]})],2)])],1),_c('div',{staticClass:"__spacet7p"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }