<template>
  <div>
    <topHeader :userData="user" />
    <b-container>
      <div class="profiledetail">
        <b-row>
          <b-col cols="12" xl="4" lg="4" md="4" sm="5"></b-col>
          <b-col cols="12" xl="8" lg="8" md="8" sm="7">
            <div class="__movleft">
              <p class="profiledetail__name">
                <template v-if="userData">
                  <span>Hey, I'm</span> {{ userData.fullName }}
                </template>
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xl="4" lg="4" md="4" sm="5">
            <div class="__fixedimg">
              <template v-if="userData">
                <profileCarousel
                  :user="userData"
                  :userSlug="this.$route.params.userSlug"
                  :loggedInUser="user"
                />
              </template>

              <template
                v-if="
                  userData &&
                  (userData.profile.facebook_url ||
                    userData.profile.twitter_url ||
                    userData.profile.instagram_url ||
                    userData.profile.linkedin_url)
                "
              >
                <hr />
                <div class="row">
                  <div
                    class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                  >
                    <a
                      :href="`${userData.profile.twitter_url}`"
                      target="_blank"
                      v-if="userData.profile.twitter_url"
                    >
                      <img
                        :src="require('../assets/img/twitter.png')"
                        class="__sociaimgs"
                      />
                    </a>
                    <a
                      :href="`${userData.profile.instagram_url}`"
                      target="_blank"
                      v-if="userData.profile.instagram_url"
                    >
                      <img
                        :src="require('../assets/img/instagram.png')"
                        class="__sociaimgs"
                      />
                    </a>
                    <a
                      :href="`${userData.profile.linkedin_url}`"
                      target="_blank"
                      v-if="userData.profile.linkedin_url"
                    >
                      <img
                        :src="require('../assets/img/linkedin.png')"
                        class="__sociaimgs"
                      />
                    </a>
                    <a
                      :href="`${userData.profile.facebook_url}`"
                      target="_blank"
                      v-if="userData.profile.facebook_url"
                    >
                      <img
                        :src="require('../assets/img/facebook.png')"
                        class="__sociaimgs"
                      />
                    </a>
                  </div>
                </div>
                <hr />
              </template>
            </div>
          </b-col>
          <b-col cols="12" xl="8" lg="8" md="8" sm="7">
            <div class="__movleft">
              <div class="profiledetail__status">
                <b-row>
                  <b-col xl="6" lg="6" md="6" sm="6">
                    <p class="profiledetail__status-exper">
                      <img
                        :src="require('../assets/img/briefcase.png')"
                        class="profiledetail__status-img"
                      />
                      Work Preferences:
                      <template v-if="userData">
                        <span
                          v-if="
                            userData.profile.work_preferences === 'full-time'
                          "
                        >
                          Full time</span
                        >
                        <span
                          v-if="
                            userData.profile.work_preferences === 'part-time'
                          "
                          >Contract</span
                        >
                        <span v-if="!userData.profile.work_preferences"
                          >N/A</span
                        >
                      </template>
                    </p>
                  </b-col>

                  <b-col xl="6" lg="6" md="6" sm="6">
                    <p class="profiledetail__status-exper">
                      <img
                        :src="require('../assets/img/currency.png')"
                        class="profiledetail__status-img"
                      />
                      Availability:
                      <template v-if="userData">
                        <span>₦{{ userData.profile.work_rate }} (Per)hour</span>
                      </template>
                    </p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col xl="6" lg="6" md="6" sm="6">
                    <p class="profiledetail__status-exper">
                      <img
                        :src="require('../assets/img/epushpin.png')"
                        class="profiledetail__status-img"
                      />
                      <template v-if="userData">
                        Location:
                        <span>{{
                          `${userData.profile.city}, ${userData.profile.country}`
                        }}</span>
                      </template>
                    </p>
                  </b-col>
                </b-row>
              </div>

              <p class="profiledetail__bio">Bio</p>
              <template v-if="userData">
                <p class="profiledetail__biodetail">
                  {{
                    userData.profile.bio.length > 500
                      ? `${userData.profile.bio.substr(0, 500)}...`
                      : userData.profile.bio
                  }}
                </p>
              </template>

              <p class="profiledetail__bio">Skills</p>
              <hr />

              <div class="__emptyboxdow" style="backgroundcolor: white">
                <template v-if="userData">
                  <p class="__emptyboxdow-pr" v-if="!userData.profile.skills">
                    You have not added any expertises, click on edit to add your
                    area(s) of expertise
                  </p>
                  <div v-if="userData.profile.skills">
                    <template
                      v-for="(skill, index) of userData.profile.skills.split(
                        ','
                      )"
                    >
                      <p
                        class="custom-chips"
                        style="justifycontent: center"
                        :key="index"
                      >
                        {{ skill }}
                      </p>
                    </template>
                  </div>
                </template>
              </div>

              <p class="profiledetail__bio">Meetings and Events</p>
              <hr />

              <template v-for="session of mentorshipSessions">
                <div class="mentocard border-radius" :key="session.id">
                  <b-row>
                    <b-col cols="12" xl="11" lg="11" md="11" sm="10">
                      <p class="mentocard__cofp">{{ session.name }}</p>
                    </b-col>
                  </b-row>

                  <div class="mentocard__flexdiv">
                    <p class="mentocard__flexdiv--disc">
                      <img :src="require('../assets/img/duration.png')" />
                      Duration:
                      <span>{{ session.duration }} h:m</span>
                    </p>

                    <p class="mentocard__flexdiv--disc">
                      <img :src="require('../assets/img/epushpin.png')" />
                      Location:
                      <template
                        v-if="
                          session.location.toLowerCase().includes('digital') &&
                          session.link.toLowerCase().includes('google')
                        "
                      >
                        <img
                          :src="require('../assets/img/google-meet.png')"
                          style="marginright: 3px"
                        />
                      </template>
                      <template
                        v-if="
                          session.location.toLowerCase().includes('digital') &&
                          session.link.toLowerCase().includes('zoom')
                        "
                      >
                        <img
                          :src="require('../assets/img/zoom.png')"
                          style="marginright: 3px"
                        />
                      </template>
                      <template
                        v-if="
                          !session.location.toLowerCase().includes('digital')
                        "
                      >
                        <img
                          :src="require('../assets/img/inperson.png')"
                          style="marginright: 3px"
                        />
                      </template>
                      <span>{{ session.location }}</span>
                    </p>

                    <p class="mentocard__flexdiv--disc">
                      <img :src="require('../assets/img/currency.png')" />
                      Availability:
                      <span>{{ session.payment_mode }}</span>
                    </p>
                  </div>

                  <router-link v-if="user.slug !== slug"
                    :to="`/${slug}/book-mentorship/${session.slug}`"
                    class="btn __mentbtn"
                    >Book Event</router-link
                  >
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
        <div class="__spacet7p"></div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import UserMixin from "../mixins/usersMixins";

import topHeader from "../components/topHeader.vue";
import profileCarousel from "../components/profileCarousel";
import { errorHandler } from "../../utils/helpers";

export default {
  mixins: [UserMixin],
  components: {
    topHeader,
    profileCarousel,
  },
  data() {
    return {
      userData: null,
      slug: "",
    };
  },
  computed: mapGetters(["mentorshipSessions", "user"]),
  methods: mapActions(["fetchSessions", "fetchUserProfile"]),
  async mounted() {
    const { userSlug } = this.$route.params;
    this.slug = userSlug;

    try {
      await this.fetchSessions(`${userSlug}`);

      const { data } = await this.fetchUserProfile(`${userSlug}`);
      this.userData = data;
    } catch (error) {
      errorHandler(error, this.$toast);
    }
  },
};
</script>

<style lang="scss">
.custom-chips {
  min-width: 94.21px;
  height: 31.21px;
  margin-top: 0;
  margin-bottom: 8px;
  background: #e5ebf5;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: space-between;
  font-size: 14px;
  color: #2357bb;
  align-items: center;
  margin-right: 10px;
  & > img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.profiledetail {
  padding-top: 7rem;
}
</style>
